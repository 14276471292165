// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontaktformular-js": () => import("./../../../src/pages/kontaktformular.js" /* webpackChunkName: "component---src-pages-kontaktformular-js" */),
  "component---src-pages-mannschaften-index-js": () => import("./../../../src/pages/mannschaften/index.js" /* webpackChunkName: "component---src-pages-mannschaften-index-js" */),
  "component---src-templates-markdown-only-js": () => import("./../../../src/templates/MarkdownOnly.js" /* webpackChunkName: "component---src-templates-markdown-only-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/Team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

